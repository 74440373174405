import React, { Component, PureComponent, Fragment } from "react"

import ShareBtn from "chrome-web-share"

import Outfit from "../assets/outfit.svg"
import Bus from "../assets/bus.svg"
import WaterBottle from "../assets/waterbottle.svg"
import KeepCup from "../assets/keepcup.svg"
import Drinks from "../assets/drinks.svg"
import Logo from "../assets/logo.svg"

import get from "lodash.get"

import Water from "./image"

const TYPES = {
  conference: "kasjdlf",
  conferenceRunway: "lksdjf",
  runway: "wooop",
}

class Button extends PureComponent {
  render() {
    const { style, children, onClick } = this.props
    return (
      <div className="button" style={style} onClick={onClick}>
        {children}
      </div>
    )
  }
}

class Footer extends Component {
  handleWebsite = () => {
    window.open("https://undressrunways.com/", "_blank")
  }

  handleNavigateToInstagram = () => {
    window.open("https://www.instagram.com/undressrunways/", "_blank")
  }

  render() {
    const { type, bothEvents } = this.props
    const isRunway = type !== "conference" && !bothEvents

    return (
      <div
        className={`footer ${type}`}
        style={{
          backgroundColor: "white",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: 0,
        }}
      >
        <div className="gradient"></div>

        <div style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: '0 40px'
        }}>
          <div className="h1" style={{ textAlign: 'center' }}>How to be an awesome Undress guest</div>
          {(isRunway || bothEvents) && (
            <Fragment>
              <Outfit style={{ marginTop: "40px", marginBottom: "15px" }} />
              <div className="details">Wear sustainable</div>
            </Fragment>
          )}
          <Bus style={{ marginTop: "40px", marginBottom: "15px" }} />
          <div className="details">Take public transport</div>
          {(!isRunway || bothEvents) && (
            <Fragment>
              <WaterBottle style={{ marginTop: "40px", marginBottom: "15px" }} />
              <div className="details">Bring your water bottle</div>
              <KeepCup style={{ marginTop: "40px", marginBottom: "15px" }} />
              <div className="details">Bring your keep cup</div>
            </Fragment>
          )}
          {isRunway && (
            <Fragment>
              <Drinks style={{ marginTop: "40px", marginBottom: "15px" }} />
              <div className="details">Drink responsibly</div>
            </Fragment>
          )}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button style={{ marginTop: "110px" }} onClick={this.handleWebsite}>
              Undress Website
            </Button>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button onClick={this.handleNavigateToInstagram}>
              Go to our Instagram
            </Button>
          </div>

          <div className="h1" style={{ marginTop: "50px" }}>
            #undressrunways
          </div>
          <Logo style={{ marginTop: "50px", marginBottom: "78px" }} />
        </div>
      </div>
    )
  }
}

export default Footer
