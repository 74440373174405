import React, { Component, Fragment } from "react"
import { Link } from "gatsby"
import QRCode from "qrcode"
import get from "lodash.get"
import camelCase from "lodash.camelcase"
import memoize from "lodash.memoize"
import dayjs from "dayjs"

import Layout from "../components/layout"
import Footer from "../components/footer"
import firebase from "../utils/firebase"

import Logo from "../assets/logo.svg"
import RedeemIcon from "@material-ui/icons/Redeem"

const WHITE = "#FFFFFF"
const BLACK = "#000000"
const PINK = "#F06C87"
const SOFT_PINK = "#fef0f3"
const GREY = "#848282"
const DARK_GREY = "#292828"

const TICKET_TIME = {
  'IkLjM64neLS9yK2iaa9j': '12PM - 6PM',
  '6vM0EWLF6BPgWW1tTQ1f': '7PM - 10PM'
}

const STATUS = {
  READY: "ready",
  LOADING: "loading",
  ERROR: "error",
}

const devTicket = {
  customer: {
    name: "Martin Wheeler",
    email: "martin@martinwheeler.com.au",
  },
  tickets: [
    {
      checkedIn: false,
      checkInTime: null,
      event: { id: "6vM0EWLF6BPgWW1tTQ1f" }, // IkLjM64neLS9yK2iaa9j
      type: { id: "lTAQjBVLn5Cky1TpCTli" }, // Seated: lTAQjBVLn5Cky1TpCTli // Standing: GxjQHYEscXNpSCOvWGae
    },
    {
      checkedIn: false,
      checkInTime: null,
      event: { id: "IkLjM64neLS9yK2iaa9j" },
      type: { id: "lTAQjBVLn5Cky1TpCTli" }
    },
    {
      checkedIn: false,
      checkInTime: null,
      event: { id: "6vM0EWLF6BPgWW1tTQ1f" },
      type: { id: "U7CZpTB2EJSeD5l3QhM4" },
    },
    {
      checkedIn: false,
      checkInTime: null,
      event: { id: "6vM0EWLF6BPgWW1tTQ1f" },
      type: { id: "GxjQHYEscXNpSCOvWGae" },
    },
    {
      checkedIn: false,
      checkInTime: null,
      event: { id: "6vM0EWLF6BPgWW1tTQ1f" },
      type: { id: "k6g197m8SdxsfHGqqTjH" },
    },
  ],
}

const generateQR = memoize(
  async (text, color = [WHITE, PINK]) => {
    const [foreground, background] = color
    try {
      const response = await QRCode.toCanvas(text, {
        errorCorrectionLevel: "H",
        width: 200,
        color: {
          dark: foreground,
          light: background,
        },
      })
        .then(canvas => {
          return { canvas, success: true }
        })
        .catch(error => {
          return { success: false, error }
        })
      return response
    } catch (err) {
      console.error(err)
    }
  },
  (text, color) => `${text}-${color.join("_")}`
)

const TICKET_TYPE_COLOURS = {
  gaStanding: [PINK, SOFT_PINK],
  gaSeated: [PINK, WHITE],
  vip: [GREY, WHITE],
  lagoonLounge: [DARK_GREY, WHITE],
  concessionPass: [DARK_GREY, WHITE],
}

// TODO: Add bugsnag
class TicketPage extends Component {
  state = {
    qrCodeCanvas: null,
    customer: {},
    status: STATUS.LOADING,
    events: [],
    ticketData: [],
  }

  canvasContainer = React.createRef()
  ticketCanvas = {}

  generateTicketCode = ticketToken => (ticket, index) => {
    const ticketType = this.state.ticketTypes[get(ticket, "type.id")]
    const ticketTypeName = camelCase(get(ticketType, "name")) || "default"
    const currentColor = TICKET_TYPE_COLOURS[ticketTypeName] || [PINK, WHITE]

    const QRCodeText = `${ticketToken}-${index}`

    if (!this.ticketCanvas[index]) {
      this.ticketCanvas[index] = React.createRef() // Used later on when rendering the QR Codes
    }

    return generateQR(QRCodeText, currentColor)
  }

  mapTicketToQRCode = (tickets, generatedCodes) =>
    (tickets || []).reduce((result, currentTicket, index) => {
      return [
        ...result,
        {
          ...currentTicket,
          qrCode: get(generatedCodes, `${index}.canvas`) || null,
          event: {
            ...this.state.events[currentTicket.event.id],
            id: currentTicket.event.id,
          },
          type: this.state.ticketTypes[currentTicket.type.id],
        },
      ]
    }, [])

  handleTicketUpdate = newTicketDocument => {
    const { ticketData, status } = this.state
    const newTickets = get(newTicketDocument.data(), "tickets") || []

    if (status === STATUS.READY) {
      this.setState({
        ticketData: ticketData.map((oldTicket, index) => {
          const { type, event, ...rest } = newTickets[index]

          return {
            qrCode: oldTicket.qrCode,
            event: oldTicket.event,
            type: oldTicket.type,
            ...rest,
          }
        }),
      })
    }
  }

  handleTicketCodeCreation = () => {
    const { location } = this.props

    const currentTokens = get(location, "hash", "")
      .replace("#", "")
      .split("&")
      .reduce((result, currentHash) => {
        if (currentHash) {
          const [key, value] = currentHash.split("=")
          result[key] = value
        }
        return result
      }, {})

    const ticketToken = get(currentTokens, "token")

    if (!ticketToken) {
      this.setState({
        error: {
          message: (
            <div>
              Sorry, there appears to be an error finding your code snippet.
              Please click <Link to="/send-code-snippet">here</Link> to resend
              the link to you via email.
            </div>
          ),
        },
      })
    } else {
      // Add ticket hash to localStorage so we can re-open it on index load
      localStorage.setItem("recentTicket", ticketToken)

      const ticketDocRef = firebase
        .firestore()
        .collection("tickets")
        .doc(ticketToken)

      ticketDocRef.get().then(documentSnapshot => {
        if (documentSnapshot.exists) {
          const { mergedId } = documentSnapshot.data()
          if (mergedId) {
            window.location.href = `/ticket/#token=${mergedId}`
            window.location.reload()
          }
        }
      })

      const fetchTicketTypes = firebase
        .firestore()
        .collection("ticketTypes")
        .get()
        .then(querySnapshot => {
          let ticketTypesData = {}
          querySnapshot.forEach(document => {
            if (document.exists) {
              ticketTypesData[document.id] = document.data()
            }
          })

          this.setState({ ticketTypes: ticketTypesData }, () => {
            return true
          })
        })

      const fetchEventTypes = firebase
        .firestore()
        .collection("events")
        .get()
        .then(querySnapshot => {
          let eventData = {}
          querySnapshot.forEach(document => {
            if (document.exists) {
              eventData[document.id] = document.data()
            }
          })

          this.setState({ events: eventData }, () => {
            return true
          })
        })

      Promise.all([fetchTicketTypes, fetchEventTypes]).then(response => {
        let ticketRequest = Promise.resolve(devTicket)

        if (ticketToken !== "dev") {
          // Update the lastOpened timestamp
          ticketRequest = firebase
            .firestore()
            .runTransaction(currentTransaction => {
              // This code may get re-run multiple times if there are conflicts.
              return currentTransaction.get(ticketDocRef).then(ticketDoc => {
                if (!ticketDoc.exists) {
                  throw "Sorry, that ticket appears to be invalid. Please re-open the ticket from the email you received."
                }

                const data = ticketDoc.data()

                currentTransaction.update(ticketDocRef, {
                  lastOpened: firebase.firestore.Timestamp.fromDate(new Date()),
                })

                // Listen for updates to the ticket
                ticketDocRef.onSnapshot(
                  {
                    includeMetadataChanges: true,
                  },
                  this.handleTicketUpdate
                )

                return { ...data, id: ticketDoc.id }
              })
            })
        }

        ticketRequest
          .then(response => {
            const tickets = get(response, "tickets") || []
            const customer = get(response, "customer") || {}
            Promise.all(tickets.map(this.generateTicketCode(ticketToken))).then(
              generatedCodes => {
                this.setState(
                  {
                    ticketData: this.mapTicketToQRCode(tickets, generatedCodes),
                    customer,
                  },
                  () => {
                    setTimeout(
                      () => this.setState({ status: STATUS.READY }),
                      500
                    )
                  }
                )
              }
            )
          })
          .catch(error => {
            console.error(error)
            this.setState({
              status: STATUS.ERROR,
              ticketData: [],
              error,
            })
          })
      })
    }
  }

  componentDidMount() {
    this.handleTicketCodeCreation()
  }

  componentDidUpdate(prevProps, prevState) {
    const { ticketData, status } = this.state
    const { location } = this.props

    if (status === STATUS.READY) {
      // FOREACH TICKET CANVAS
      Object.keys(this.ticketCanvas).forEach(canvasId => {
        this.ticketCanvas[canvasId].current.innerHTML = ""
        this.ticketCanvas[canvasId].current.appendChild(
          ticketData[canvasId].qrCode
        )
      })
    }

    if (prevProps.location.hash !== location.hash) {
      this.setState({ status: STATUS.LOADING }, this.handleTicketCodeCreation)
    }
  }

  render() {
    const { error, status, ticketData, customer } = this.state
    const bothEvents =
      Object.keys(
        ticketData.reduce((result, currentTicket) => {
          const eventId = get(currentTicket, "event.id")

          if (!result[eventId]) {
            result[eventId] = true
          }

          return result
        }, {})
      ).length === 2

    return (
      <Layout isLoading={status === STATUS.LOADING} className={"ticket"}>
        {status == STATUS.ERROR ? (
          <div>{error.message || error}</div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: status === STATUS.LOADING ? "100vh" : "auto",
            }}
          >
            {status === STATUS.READY &&
              ticketData.map((ticket, index) => {
                const lastTicket = index === ticketData.length - 1
                const ticketType =
                  ticket.type && ticket.type.name
                    ? ticket.type.name
                    : ticket.type
                const styleType = ticketType.toLowerCase().replace(/\s/g, "-")
                const checkedIn = get(ticket, "checkedIn", false)
                const free = get(ticket, "free", false)

                return (
                  <Fragment key={index}>
                    <div
                      className={`wrapper ${styleType} ${
                        checkedIn ? "checkedIn" : ""
                      }`}
                    >
                      <Logo
                        style={{
                          color: "currentColor",
                          marginTop: "28px",
                          marginBottom: "28px",
                        }}
                      />
                      <div className="h1">{get(ticket, "event.name")}</div>
                      <div className="h2">
                        {get(ticket, "event.location.state")}
                      </div>
                      {free && (
                        <RedeemIcon
                          style={{ marginBottom: "-18px", marginTop: "8px" }}
                        />
                      )}
                      <div
                        style={{
                          margin: "30px 0",
                          display: "flex",
                        }}
                        ref={this.ticketCanvas[index]}
                      ></div>
                      <div className="label">Time & Date</div>
                      <div className="details">
                        {TICKET_TIME[get(ticket, 'event.id')]}
                      </div>
                      <div className="details">
                        November 22, 2019
                      </div>
                      <div className="label" style={{ marginTop: "30px" }}>
                        Location
                      </div>
                      <div className="details">
                        {get(ticket, "event.location.business")}
                      </div>
                      <div className="details">
                        {get(ticket, "event.location.address")}
                      </div>
                      <div className="ticketCount">
                        {ticketData.length > 1 &&
                          `${index + 1} of ${ticketData.length}`}
                      </div>
                      <div className="ticketType">{ticketType}</div>
                      <div className="details customer">
                        {get(customer, "name")}
                      </div>
                      <div className="details customer email">
                        {get(customer, "email")}
                      </div>
                    </div>
                    {lastTicket && (
                      <Footer bothEvents={bothEvents} type={styleType} />
                    )}
                  </Fragment>
                )
              })}

            {status === STATUS.LOADING && (
              <div className="loading">Loading your tickets...</div>
            )}
          </div>
        )}
      </Layout>
    )
  }
}

export default TicketPage
